
.display-none-on-big-devices{
    display: none;
}

footer {
    text-align: center;
    margin-top: 20px;
}
i{
    margin-left: 10px;
}
a{
    color:black;
}
a:hover{
    color:#532f49;
}
.container-sm{
    /* width: 60%;
    height: 75%; */
    padding: 30px;
    background-color:#aeb0b5 ;
    margin-left: 20%;
    margin-right: 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
}
.title{
    text-align: center;
}
@media screen and (max-width:1024px) {
    .container-sm{
        margin: 0;

       
    }
    
 }
 
 @media screen and (max-width:600px) {
    .none-on-mediaquery{
        display: none;
    }
    .display-none-on-big-devices{
        display: block;
    }
    .title{
        font-size: large;
        font-weight: bold;
    }
    .non-on-mediaquery{
        display: none;
    }
 }
 
