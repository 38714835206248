.main{
    
    width:auto;
    margin:20px;
   
    
}


.me{
    width: 70%;
    height: auto;
    margin-top: 60px;
    
  
}
.me:hover{
    transform:rotate(10deg);
    
}
p{
    text-align: start;
    
}
/* .title{
    margin-top: 20px;
} */

@media screen and (max-width:1024px) {
    .me{
        width: 100%;
        height: auto;
    }
    p{
        font-size:large ; 
     }
}

@media screen and (max-width:600px) {
    .me{
        
        width: 60%;
        height: auto;
        min-width:47px;
    }
    .main{
        margin: 0px;
    }
    p{
       font-size:large ; 
    }
    .title{
        font-size:x-large;
    }
}