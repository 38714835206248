
.portfolio-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}
.portfolio-item{
    background: #d6d7d9;
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    text-align: center;

}
.portfolio-item:hover{
    border-color: #532f49;
    background: transparent;
    
}
.portfolio-item:hover .hover{
    display: inline-block;
    
}
.portfolio-item-img{
    width: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
    cursor: pointer;
}
.hover{
    margin-top: 1rem;
    display: none;
}
.portfolio-item h5{
    margin: 1rem;
    font-weight: bold;
}

.m{
    background: rgba(0, 0, 0, .65);
    bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top:0;
	z-index: 1;
}
.m-con{
    background:#aeb0b5;
    border-radius: 5px ;
	max-width: 100%;
    top: 50px;
	margin: 50px auto;
	padding: 15px;
	width:500px;
    
}
.m-title{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
    
}
.p-description{
      background-color:#d6d7d9 ;
      padding: 20px; ;
}
.m-btn {
	background: #532f49;
	border: 0;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;	
}



@media screen and (max-width:1024px) {
    .portfolio-container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width:600px) {
    .portfolio-container{
        grid-template-columns: 1fr ;
        gap: 1rem;
    }
   
}
