.nav-link{
    color: #212121;
}
.active {
    background-color:gray;
}
a:active{
    text-decoration: none;
    
    
}
a:hover{
    text-decoration:none;
}
@media screen and (max-width:1024px) {
    
 }
 
 @media screen and (max-width:600px) {
   /* .nav-bar-onFullscreen{
    display: none;
   } */
 }